import React, {useState, useEffect}  from 'react';
import { Link } from "react-router-dom";
import { useMutation } from "react-query";
import apiClient from "../../http-common";
import { useNavigate } from "react-router-dom";
import {
  triggerSuccess,
  triggerError,
  setErrorMessage,
  setSuccessMessage,
} from '../Request/StatusFormatter';

const Login = () => {
  let navigate = useNavigate();
    const initialLogin = {
        email: '',
        password: '',
    }
    const [loginForm, setLoginForm] = useState(initialLogin);
    const onChange = (e) =>
    setLoginForm({ ...loginForm, [e.target.name]: e.target.value });

    const { isLoading: isSendingRequest, mutate: postLogin } = useMutation(
        async () => {
          return await apiClient.post(`/api/login`, {
            email: loginForm.email,
            password: loginForm.password,
          });
        },
        {
          onSuccess: (res) => {
            if (res.data.message === 'unverified') {
              navigate('/verify-user');
            } else {              
            setSuccessMessage(res.data);
            triggerSuccess();
            // console.log(JSON.stringify(res.data.user));
            localStorage.setItem('auth-token', res.data.access_token);
            localStorage.setItem('delox-user', JSON.stringify(res.data.user));
            localStorage.setItem('plans', JSON.stringify(res.data.plans));
            localStorage.setItem('wallets', JSON.stringify(res.data.wallets));
            sessionStorage.setItem('delox-user', JSON.stringify(res.data.user));

            window.location.href = '/';
            }
            
          },
          onError: (err) => {
            setErrorMessage(err.response?.data || err);  
            triggerError();
          },
        }
      );
      const onSubmit = async() =>{
        postLogin();
        // await apiClient.get("/sanctum/csrf-cookie").then(() => {
          
        // })
      }   

      useEffect(() => {
        document.title = 'LOGIN';
      });
  return (
    <div className="page-content my-0 py-0">
        <div className="card card-fixed">
            <div className="card-center mx-3 px-4 py-4 bg-transparent rounded-m">
                <div className="text-center d-flex justify-content-center mb-4">
                  <img src="https://res.cloudinary.com/dzxyvdq14/image/upload/v1691336983/seariesglobal-logo.png" height="60" alt="logo"/>
                </div>
                <h1 className="font-30 font-800 mb-0 text-center text-white">Account Login</h1>
                <p className='text-center' style={{color: '#eff1f0'}}>Fill the form below to login to your Account.</p>
                <div className="form-custom form-label form-border form-icon mb-3" style={{border: '1px solid #ffffff', borderRadius: '8px'}}>
                    <i className="text-white bi bi-person-circle font-13"></i>
                    <input style={{color: '#f9fbfa'}} autoFocus='off' autoComplete="off" type="text" name="email" onChange={onChange} className="form-control login-forms rounded-xs" id="email" placeholder="enter your email"/>
                    <label htmlFor="email" className="text-white">Email</label>
                    <span style={{color: '#eff1f0'}}>(required)</span>
                </div>
                <div className="form-custom form-label form-border form-icon mb-4"  style={{border: '1px solid #ffffff', borderRadius: '8px'}}>
                    <i className="text-white bi bi-asterisk font-13"></i>
                    <input autoFocus='off' autoComplete="off" style={{color: '#f9fbfa'}} type="password" onChange={onChange} name="password" className="form-control text-white rounded-xs" id="password" placeholder="Enter password"/>
                    <label htmlFor="password" className="text-white">Password</label>
                    <span style={{color: '#eff1f0'}}>(required)</span>
                </div>
                <div className="form-check form-check-custom">
                    <input className="form-check-input" type="checkbox" name="type" value="" id="remember"/>
                    <label className="form-check-label font-12" htmlFor="remember">Remember this account</label>
                    <i className="is-checked color-highlight font-13 bi bi-check-circle-fill"></i>
                    <i className="is-unchecked color-highlight font-13 bi bi-circle"></i>
                </div>
                
                <div onClick={onSubmit} className="btn btn-full gradient-green shadow-bg shadow-bg-s mt-4">
                  {isSendingRequest? <span>sending...</span>: <span>Login</span>}
                </div>
                <div className="row">
                    <div className="col-6 text-start">
                        <Link to="/forgot-password" className="font-14 color-green-light font-weight-bold pt-4 d-block">Forgot Password?</Link>
                    </div>
                    <div className="col-6 text-end">
                        <Link to="/register" className="font-14 color-green-light pt-4 d-block">Create Account</Link>
                    </div>
                </div>
            </div>
            <div style={{backgroundImage: "url(https://res.cloudinary.com/dzxyvdq14/image/upload/v1675854218/beacongb.png)"}} className="card-overlay rounded-0 m-0 opacity-90 gradient-green">
            </div>
        </div>

    </div>
  )
}

export default Login